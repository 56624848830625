import { Spinner } from "native-base";
import React, {
  ComponentType,
  ReactNode,
  Suspense,
  SuspenseProps,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useQueryClient } from "react-query";
import { log } from "../utils/logging";

function FallbackSpinner({ useSafeArea }: { useSafeArea: boolean }) {
  const insets = useSafeAreaInsets();
  const marginY = 50 + (useSafeArea ? insets.top : 0);
  // Only show spinner after 500 ms
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShowSpinner(true), 500);
    return () => clearTimeout(timeout);
  }, []);

  // Recording effect
  if (__DEV__) {
    const queryClient = useQueryClient();
    useLayoutEffect(() => {
      const now = Date.now();
      const fetchingQueries = queryClient
        .getQueryCache()
        .findAll({ fetchStatus: "fetching" });
      return () => {
        log(
          "Ended suspense after",
          Date.now() - now,
          "ms while these queries were running:",
          fetchingQueries.map((q) => q.queryKey)
        );
      };
    }, []);
  }

  return showSpinner ? (
    <Spinner
      color="black"
      marginX="auto"
      style={{ marginTop: marginY, marginBottom: marginY }}
    />
  ) : null;
}

/**
 * Suspense with a reasonable fallback element that doesn't look terrible.
 * Currently just a basic spinner using our brand color.
 */
export default function BetterSuspense({
  children,
  useSafeArea = false,
}: {
  children: ReactNode;
  useSafeArea?: boolean;
}) {
  return (
    <Suspense fallback={<FallbackSpinner useSafeArea={useSafeArea} />}>
      {children}
    </Suspense>
  );
}

/**
 * Wraps the provide component in a `Suspense`, with the provided fallback.
 * This should be used on components whose parent is not easy to control, such as
 * React Navigation screens to be able to lazy load them using `React.lazy`.
 * @param WrappedComponent The component to wrap.
 * @param fallback The component to render while loading.
 *
 * @example
 * const SomeScreen = withSuspense(React.lazy(() => import("path/to/some/screen")));
 */
export function withSuspense<P extends string | number | object>(
  WrappedComponent: ComponentType<P>,
  fallback: SuspenseProps["fallback"] = null
) {
  function ComponentWithSuspense(props: P) {
    return (
      <BetterSuspense>
        <WrappedComponent {...props} />
      </BetterSuspense>
    );
  }

  return ComponentWithSuspense;
}
