/**
 * This file mocks expo-notifications with a no-op in development. This is
 * required because a bug in expo-dev-client causes it to crash when reloading
 * the app. https://github.com/expo/expo/issues/15788
 */

/** @type {typeof import('expo-notifications/src/index')} */
const mockedNotifications = {
  getDevicePushTokenAsync: () => Promise.resolve({}),
  getExpoPushTokenAsync: () => Promise.resolve({}),
  getPresentedNotificationsAsync: () => Promise.resolve({}),
  presentNotificationAsync: () => Promise.resolve({}),
  dismissNotificationAsync: () => Promise.resolve({}),
  dismissAllNotificationsAsync: () => Promise.resolve({}),
  getNotificationChannelsAsync: () => Promise.resolve({}),
  getNotificationChannelAsync: () => Promise.resolve({}),
  setNotificationChannelAsync: () => Promise.resolve({}),
  deleteNotificationChannelAsync: () => Promise.resolve({}),
  getNotificationChannelGroupsAsync: () => Promise.resolve({}),
  getNotificationChannelGroupAsync: () => Promise.resolve({}),
  setNotificationChannelGroupAsync: () => Promise.resolve({}),
  deleteNotificationChannelGroupAsync: () => Promise.resolve({}),
  getBadgeCountAsync: () => Promise.resolve({}),
  setBadgeCountAsync: () => Promise.resolve({}),
  getAllScheduledNotificationsAsync: () => Promise.resolve({}),
  scheduleNotificationAsync: () => Promise.resolve({}),
  cancelScheduledNotificationAsync: () => Promise.resolve({}),
  cancelAllScheduledNotificationsAsync: () => Promise.resolve({}),
  getNotificationCategoriesAsync: () => Promise.resolve({}),
  setNotificationCategoryAsync: () => Promise.resolve({}),
  deleteNotificationCategoryAsync: () => Promise.resolve({}),
  getNextTriggerDateAsync: () => Promise.resolve({}),
  useLastNotificationResponse: () => Promise.resolve({}),
  setAutoServerRegistrationEnabledAsync: () => Promise.resolve({}),
  registerTaskAsync: () => Promise.resolve({}),
  unregisterTaskAsync: () => Promise.resolve({}),
  addPushTokenListener: () => Promise.resolve({}),
  removePushTokenSubscription: () => Promise.resolve({}),
  addNotificationReceivedListener: () => Promise.resolve({}),
  addNotificationsDroppedListener: () => Promise.resolve({}),
  addNotificationResponseReceivedListener: () => Promise.resolve({}),
  removeNotificationSubscription: () => Promise.resolve({}),
  getLastNotificationResponseAsync: () => Promise.resolve({}),
  setNotificationHandler: () => Promise.resolve({}),
  getPermissionsAsync: () => Promise.resolve({}),
  requestPermissionsAsync: () => Promise.resolve({}),
  usePermissions: () => Promise.resolve({}),
  AndroidAudioContentType: {},
  AndroidAudioUsage: {},
  AndroidImportance: {},
  AndroidNotificationPriority: {},
  AndroidNotificationVisibility: {},
  DEFAULT_ACTION_IDENTIFIER: "DEFAULT_ACTION_IDENTIFIER",
  IosAlertStyle: {},
  IosAllowsPreviews: {},
  IosAuthorizationStatus: {},
  NotificationTimeoutError: {},
};

const toExport: typeof import("expo-notifications") = {
  ...mockedNotifications,
  //@ts-expect-error
  default: mockedNotifications,
};

export default toExport;
