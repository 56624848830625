import { Platform } from "react-native";
import * as Sentry from "sentry-expo";
import { isTestMode } from "../api/isTestMode";

import { log } from "./logging";

export function captureException(error: any) {
  if (isTestMode()) {
    log("Suppressing log of", error, "since we're in test mode");
    return;
  }
  if (Platform.OS === "web") {
    Sentry.Browser.captureException(error);
  } else {
    Sentry.Native.captureException(error);
  }
}

export function captureMessage(message: string) {
  if (isTestMode()) {
    console.log(message);
    return;
  }
  if (Platform.OS === "web") {
    Sentry.Browser.addBreadcrumb({
      category: "console",
      message,
      level: Sentry.Browser.Severity.Info,
    });
  } else {
    Sentry.Native.addBreadcrumb({
      category: "console",
      message,
      level: Sentry.Native.Severity.Info,
    });
  }
}
