import Constants from "expo-constants";
import { merge } from "lodash";
import { string } from "prop-types";
import { useRef } from "react";
import { Platform } from "react-native";
import {
  QueryClient,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";

// @ts-ignore
import groupPfp from "../assets/images/blank-group-picture.png";
// @ts-ignore
import blankPfp from "../assets/images/blank-profile-picture.png";
import { getIdToken } from "../auth/id-token";
import { analytics } from "../init/mixpanel";
import { dataURLtoBlob } from "../utils/blobs";
import { log } from "../utils/logging";
import {
  Referral,
  ReferralRequest,
  ConnectionRequest,
  Connection,
  UserLookupResult,
  UserPublic,
  Room,
  Message,
  Group,
  GroupWithParticipants,
  User,
  Blurb,
  Notification,
  BlurbRecommendation,
  ReferralRecommendation,
  Activity,
  UserMine,
  Invite,
} from "./data";
import { getFlags } from "./flags";
import { isTestMode } from "./isTestMode";

const { manifest } = Constants;

export class MutationError extends Error {
  public code: number;
  public body: any;

  constructor(message: string, code: number, body: any) {
    super(message);
    this.code = code;
    this.body = body;
  }
}

export async function getSiteHost() {
  const flags = await getFlags();
  if (flags.useProdApiInDev) {
    return "https://test.chordapp.io";
  }
  const inProd = !(__DEV__ || isTestMode());

  if (Platform.OS === "web") {
    if (inProd && location.host === "web.chordapp.io") {
      return "https://chordapp.io";
    } else if (inProd) {
      return "https://test.chordapp.io";
    } else {
      return "http://localhost:3000";
    }
  } else {
    if (inProd) {
      return "https://chordapp.io";
    } else {
      return (
        "http://" + manifest!.debuggerHost!.split(`:`).shift()!.concat(`:3000`)
      );
    }
  }
}

export async function getApiHost(): Promise<string> {
  return (await getSiteHost()) + "/api";
}

export const useApiHost = generateQueryHook(getApiHost, () => ["apiHost"]);

export const useSiteHost = generateQueryHook(getSiteHost, () => ["siteHost"]);

/**
 * Unique query key for a given api request.
 */
export function queryKey(path: string) {
  return ["api-query", path];
}

async function apiMutation<TBody = any>(
  path: string,
  method: string,
  body: TBody
) {
  const res = await fetch((await getApiHost()) + path, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await getIdToken()),
    },
    body: JSON.stringify(body),
  });

  if (!res.ok) {
    const body = await res.json();
    throw new MutationError(
      `UNHANDLED API MUTATION FAILURE
API Host: ${await getApiHost()}
Path: ${path}
Status: ${res.status}
Body: ${JSON.stringify(body)}`,
      res.status,
      body
    );
  }

  return await res.json();
}

export async function apiQuery<TData = any>(
  path: string,
  throwErrorOnFailure: boolean
) {
  const res = await fetch((await getApiHost()) + path, {
    headers: {
      Authorization: "Bearer " + (await getIdToken()),
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    if (throwErrorOnFailure) {
      throw new Error(
        `UNHANDLED API QUERY FAILURE
API Host: ${await getApiHost()}
Path: ${path}
Status: ${res.status}
Body:
${await res.text()}`
      );
    } else {
      return null;
    }
  }

  return (await res.json()) as TData;
}

type NonUndefined<T> = T extends undefined ? never : T extends void ? never : T;

/**
 * A wrapper function that generates a Suspense-ready hook with:
 *
 * - The actual query function
 * - Function to get the query key for the given params
 * - Hook to prefetch the query
 */
export function generateQueryHook<TData, TParams = void>(
  fetcher: (params: TParams) => Promise<NonUndefined<TData>>,
  getQueryKey: (params: TParams) => QueryKey,
  defaultQueryOptions?: UseQueryOptions<NonUndefined<TData>, any>
) {
  const hook = (
    params: TParams,
    queryOptions?: UseQueryOptions<NonUndefined<TData>, any>
  ) => {
    const query = useQuery(
      getQueryKey(params),
      async () => {
        log("Fetching query", getQueryKey(params));
        const now = Date.now();
        const res = await fetcher(params);
        log("Fetched query", getQueryKey(params), "in", Date.now() - now, "ms");
        return res;
      },
      merge({}, defaultQueryOptions, queryOptions)
    );

    // Remove null check requirement
    return { ...query, data: query.data! };
  };

  /**
   * Prefetch a query.
   */
  hook.usePrefetch = (params: TParams) => {
    const loaded = useRef(false);
    const queryClient = useQueryClient();

    if (!loaded.current) {
      hook.prefetch(queryClient, params);
      loaded.current = true;
    }
  };

  hook.prefetch = (queryClient: QueryClient, params: TParams) => {
    const key = getQueryKey(params);
    log("Prefetching query", key);
    setImmediate(() => {
      queryClient.prefetchQuery(key, () => fetcher(params));
    });
  };

  /**
   * Get the key for a query, e.g. to invalidate it.
   */
  hook.queryKey = (params: TParams) => getQueryKey(params);

  return hook;
}

export function nullableQuery<TRestOfData, TData>(
  res: TRestOfData & { data: TData }
) {
  const x = res as Omit<TRestOfData, "data"> & { data: TData | undefined };
  return x;
}

function generateApiQueryHook<TData = any, TParams = void>(
  getPath: (params: TParams) => string,
  throwErrorOnFailure: boolean = true
) {
  async function fetcher(params: TParams) {
    const path = getPath(params);
    return await apiQuery(path, throwErrorOnFailure);
  }

  const hook = generateQueryHook<TData, TParams>(fetcher, (params) =>
    queryKey(getPath(params))
  );

  return hook;
}

/**
 * A wrapper function that generates a mutation hook for an API request.
 */
function generateApiMutationHook<TRequestData, TResponseBody, TParams = void>(
  configurer: (
    params: TParams,
    profile: UserMine
  ) => {
    /**
     * The path for the api request. E.g. `/update_profile`.
     */
    path: string;
    /**
     * Method to use for the request. E.g. "POST".
     */
    method: string;
    /**
     * Given request data specified by the calling function, return the body to
     * send to the server. By default, this is an identity function.
     */
    getRequestBody?: (data: TRequestData) => any;
    /**
     * Queries to invalidate if successful.
     */
    invalidateQueries?: QueryKey[];
    /**
     * Function to call before the request is sent.
     */
    beforeMutate?: (data: TRequestData) => void;
  }
) {
  return (params: TParams) => {
    const queryClient = useQueryClient();
    const { data: profile } = useProfile();
    const { path, method, getRequestBody, invalidateQueries, beforeMutate } =
      configurer(params, profile);

    const requestBody = getRequestBody ?? ((data) => data);
    const queries = invalidateQueries ?? [];

    return useMutation(
      async (data: TRequestData) => {
        const body = requestBody(data);
        if (beforeMutate) beforeMutate(data);
        const res = await apiMutation(path, method, body);
        return res as TResponseBody;
      },
      {
        onSuccess() {
          queries.forEach((queryKey) => {
            queryClient.invalidateQueries(queryKey);
          });
        },
      }
    );
  };
}

export function useApiMutation<TBody, TData = unknown>(
  path: string,
  method: string,
  invalidateQueries: QueryKey[] = []
) {
  const queryClient = useQueryClient();
  return useMutation(
    async (body: TBody): Promise<TData> =>
      await apiMutation(path, method, body),
    {
      onSuccess() {
        log("From mutation", path);
        log("Invalidating", invalidateQueries);
        for (const query of invalidateQueries) {
          queryClient.invalidateQueries(query);
        }
      },
    }
  );
}

export const useProfile = generateApiQueryHook<UserMine>(() => "/user", false);
export const useUserStatus = () => {
  const { data: profile } = useProfile();
  switch (true) {
    case profile === null:
      return "not-logged-in";
    case !profile.completedOnboarding:
      return "onboarding";
    default:
      return "logged-in";
  }
};

/**
 * Returns an avatar source suitable for use with an <Image /> element. If the
 * user hasn't set an avatar, returns a hardcoded blank profile picture.
 */
export function useAvatarSource(
  userAvatarUrl: string | undefined,
  isGroup: boolean = false
): { uri: string } | number {
  const { data: host } = useSiteHost();
  if (userAvatarUrl) {
    return { uri: host + userAvatarUrl };
  } else {
    return isGroup ? groupPfp : blankPfp;
  }
}

export const useMutateProfile = () => {
  const { data: profile } = useProfile();
  return useApiMutation<{
    user: Partial<UserMine>;
  }>("/user", "PUT", [
    useProfile.queryKey(),
    usePublicProfile.queryKey(profile.id),
  ]);
};

export const useAddUserToMailingList = generateApiQueryHook(
  () => "/add_to_mailing_list"
);

export const useCreateNotificationTarget = generateApiMutationHook<
  {
    notificationTarget: { svc: "expo" | "web"; token: string };
  },
  unknown,
  void
>(() => ({
  path: "/user/notification_targets",
  method: "POST",
}));

export const useDestroyNotificationTarget = generateApiMutationHook<
  {
    notificationTarget: { svc: "expo" | "web"; token: string };
  },
  unknown,
  void
>(() => ({
  path: "/user/notification_targets/destroy",
  method: "DELETE",
}));

export const useIntersectContacts = () =>
  useApiMutation<
    { contacts: { phoneNumber?: string; email?: string }[] },
    // False or ID
    (false | string)[]
  >("/users/intersect", "POST");

export const useReferralRequests = generateApiQueryHook<ReferralRequest[]>(
  () => "/referral_requests"
);

export const useReferralRequest = generateApiQueryHook<ReferralRequest, string>(
  (id) => `/referral_requests/${id}`
);

export const usePersonalReferralRequests = generateApiQueryHook<
  ReferralRequest[]
>(() => "/referral_requests/mine");

/**
 * The server allows you to fetch connections of you or anyone you're connected
 * with on Chord.
 */
export const useConnections = generateApiQueryHook<
  Connection[],
  string | undefined
>((id?: string) =>
  id !== undefined ? `/users/${id}/connections` : `/user/connections`
);

export const useSecondDegreeConnections = generateApiQueryHook<
  (User & { connectedThrough: string; mutualCount: number })[]
>(() => "/user/second_degree_connections");

export const useConnectionRequests = generateApiQueryHook<ConnectionRequest[]>(
  () => "/connection_requests"
);
export const useConnectionRequest = generateApiQueryHook<
  ConnectionRequest,
  string
>((id) => `/connection_requests/${id}`);

export const useIncomingReferrals = generateApiQueryHook<Referral[]>(
  () => "/user/incoming_referrals"
);
export const useReferral = generateApiQueryHook<Referral, string>(
  (id) => `/referrals/${id}`
);

export const useReferralNotifs = generateApiQueryHook<Referral[]>(
  () => "/user/referral_notifs"
);

export const useOutgoingReferrals = generateApiQueryHook<Referral[]>(
  () => "/user/outgoing_referrals"
);

export const useRespondToReferral = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation<{ roomId?: string }, any, { response: boolean }>(
    async ({ response }: { response: boolean }) => {
      return await apiMutation(`/referrals/${id}/respond`, "POST", {
        response,
        id,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey("/user/incoming_referrals"));
        queryClient.invalidateQueries(queryKey("/rooms"));
        queryClient.invalidateQueries(useReferral.queryKey(id));
      },
    }
  );
};
export const useReferralBountyDismiss = (id: string) =>
  useApiMutation<void>(`/referrals/${id}/bounty_dismiss`, "POST", [
    useIncomingReferrals.queryKey(),
  ]);

export const useRespondToConnectionReq = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ response }: { response: boolean }) => {
      return await apiMutation(`/connection_requests/${id}/respond`, "POST", {
        response,
        id,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey("/user/connections"));
        queryClient.invalidateQueries(queryKey("/connection_requests"));
        queryClient.invalidateQueries(useConnectionRequest.queryKey(id));
      },
    }
  );
};

export const usePublicProfile = generateApiQueryHook<UserPublic, string>(
  (idOrUsername: string) => `/users/${idOrUsername}/public_profile`
);

export const usePathfind = generateApiQueryHook<User[], string>(
  (id: string) => `/users/${id}/pathfind`
);

export const useLookupUser = () =>
  useApiMutation<
    {
      id?: string;
      name?: string;
      phoneNumber?: string;
      email?: string;
      avatarUrl?: string;
    },
    UserLookupResult
  >("/users/lookup", "POST");

export const useFindUserByName = () =>
  useApiMutation<
    {
      name: string;
    },
    UserLookupResult[]
  >("/users/public_lookup", "POST");

type CreateReferralData = {
  // For ghost users
  referrerId?: string;
  referentAId: string;
  referentBId: string;
  descriptionForA: string;
  descriptionForB: string;
  isInterop: boolean;
  referralRequestId?: string;
  visibility: string;
  referralRecommendationId?: string;
};

export const useCreateReferral = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      referrerId,
      referentAId,
      referentBId,
      descriptionForA,
      descriptionForB,
      visibility,
      referralRequestId,
      referralRecommendationId,
    }: CreateReferralData) => {
      return await apiMutation("/referrals", "POST", {
        referral: {
          referrerId,
          referentAId,
          referentBId,
          descriptionForA,
          descriptionForB,
          visibility,
          referralRequestId,
          referralRecommendationId,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useOutgoingReferrals.queryKey());
        queryClient.invalidateQueries(useReferralRecommendations.queryKey());
      },
    }
  );
};

export const useUploadAvatar = () => {
  const queryClient = useQueryClient();
  const { data: profile } = useProfile();

  return useMutation(
    async (avatarUri: string) => {
      log("Uploading avatar", avatarUri);
      const data = new FormData();
      const fileExtension = avatarUri.substring(avatarUri.lastIndexOf(".") + 1);

      if (Platform.OS === "web") {
        // On web, RN uses the native version of FormData. To upload a file
        // here, we have to create a blob from the uri (which is a base64 string
        // rather than a uri to any local file).
        const blob = dataURLtoBlob(avatarUri);
        data.append("avatar", blob, `avatar`);
      } else {
        // React Native supports a non-standard version of FormData that can
        // upload files simply by providing an object of {uri, name, type}. See
        // https://github.com/facebook/react-native/blob/v0.63.3/Libraries/Network/FormData.js#L17
        // for details.
        data.append("avatar", {
          uri: avatarUri,
          name: "avatar." + fileExtension,
          type: "image/" + fileExtension,
        } as any);
      }
      const hdrs =
        Platform.OS === "web"
          ? {}
          : {
              "Content-Type": "multipart/form-data",
            };

      const res = await fetch((await getApiHost()) + "/user/update_avatar", {
        method: "POST",
        headers: {
          ...hdrs,
          Authorization: "Bearer " + (await getIdToken()),
        } as any,
        body: data,
      });

      if (!res.ok) {
        throw new Error("Failed to update avatar");
      }

      return await res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(usePublicProfile.queryKey(profile.id));
      },
    }
  );
};

export const useBlockUser = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (targetId: string) => {
      log("Blocking", targetId);
      await apiMutation("/blocks", "POST", {
        targetId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey("/referral_requests"));
        queryClient.invalidateQueries(queryKey("/user/outgoing_referrals"));
        queryClient.invalidateQueries(queryKey("/user/incoming_referrals"));
      },
    }
  );
};

export const useCreateReferralRequest = generateApiMutationHook<
  { referralRequest: ReferralRequest },
  ReferralRequest
>(() => ({
  path: "/referral_requests",
  method: "POST",
  invalidateQueries: [usePersonalReferralRequests.queryKey()],
}));

export const useCreateConnectionRequest = () =>
  useApiMutation<{ id: string }>(
    "/connection_requests/create_general",
    "POST",
    [useSentConnectionRequests.queryKey(), useConnectionRequests.queryKey()]
  );

export const useSentConnectionRequests = generateApiQueryHook<
  { senderId: string; receiverId: string; status: string }[]
>(() => "/connection_requests/sent");

export const useMarkReferralRequestFulfilled = (id: string) => {
  const { data: profile } = useProfile();
  return useApiMutation<void, any>(`/referral_requests/${id}/fulfill`, "POST", [
    usePublicProfile.queryKey(profile?.id),
  ]);
};

// Chat
export const useRooms = generateApiQueryHook<Room[]>(() => "/rooms");
export const useRoom = generateApiQueryHook<Room, string>(
  (id) => `/rooms/${id}`
);

export const useMessages = (id: string) =>
  useInfiniteQuery<{ data: Message[]; nextPage: number }>(
    queryKey(`/rooms/${id}/messages`),
    async ({ pageParam }) => {
      return (await apiQuery(`/rooms/${id}/messages?page=${pageParam}`, true))!;
    },
    {
      getNextPageParam: (data) => data.nextPage,
    }
  );

export const useSendMessage = (id: string) =>
  useApiMutation<{ text: string; id: string }>(`/rooms/${id}/messages`, "POST");

// Groups

export const useGroups = generateApiQueryHook<Group[]>(() => "/groups");
export const useGroup = generateApiQueryHook<GroupWithParticipants, string>(
  (id: string) => "/groups/" + id
);
export const useGroupReferralRequests = generateApiQueryHook<
  ReferralRequest[],
  string
>((id: string) =>
  id === "personal" || id === "all"
    ? "/referral_requests"
    : `/groups/${id}/referral_requests`
);
export const useJoinGroup = () =>
  useApiMutation<{ inviteCode: string }>("/groups/join", "POST", [
    queryKey("/groups"),
  ]);
export const useLeaveGroup = (id: string) =>
  useApiMutation<void>("/groups/" + id + "/leave", "DELETE", [
    queryKey("/groups"),
  ]);

// Blurbs
export const useIncomingBlurbs = generateApiQueryHook<Blurb[]>(
  () => "/blurbs/incoming"
);
export const useOutgoingBlurbs = generateApiQueryHook<Blurb[]>(
  () => "/blurbs/outgoing"
);
export const useBlurb = generateApiQueryHook<Blurb, string>(
  (id: string) => "/blurbs/" + id
);
export const useSentBlurbs = generateApiQueryHook<Blurb[]>(() => "/blurbs");
export const useCreateBlurb = () =>
  useApiMutation<{
    blurb: {
      referrerId: string;
      targetId: string;
      description: string;
      blurbRecommendationId?: string;
    };
  }>("/blurbs", "POST");
export const useRespondToBlurb = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (body: { accepted: boolean; desc?: string }) => {
      await apiMutation("/blurbs/" + id + "/respond", "POST", body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(useIncomingBlurbs.queryKey());
        queryClient.invalidateQueries(useBlurb.queryKey(id));
      },
    }
  );
};

// Notifications
export const useNotifications = generateApiQueryHook<Notification[]>(
  () => "/notifications"
);
export const useNotificationCount = generateApiQueryHook<number>(
  () => "/notifications/unread"
);
export const useClearNotifications = generateApiMutationHook(() => ({
  path: "/notifications/clear",
  method: "POST",
  invalidateQueries: [useNotificationCount.queryKey()],
}));

// Recommendations
export const useBlurbRecommendations = generateApiQueryHook<
  BlurbRecommendation[]
>(() => "/blurb_recommendations");
export const useUpdateBlurbRecommendation = generateApiMutationHook<
  { status: "accepted" | "rejected" },
  unknown,
  string
>((id) => ({
  path: "/blurb_recommendations/" + id,
  method: "PUT",
  getRequestBody(data) {
    return { recommendation: data };
  },
  invalidateQueries: [useBlurbRecommendations.queryKey()],
  beforeMutate() {
    analytics.track("Updated Blurb Recommendation");
  },
}));

export const useReferralRecommendations = generateApiQueryHook<
  ReferralRecommendation[]
>(() => "/referral_recommendations");
export const useUpdateReferralRecommendation = generateApiMutationHook<
  { status: "accepted" | "rejected" },
  unknown,
  string
>((id) => ({
  path: "/referral_recommendations/" + id,
  method: "PUT",
  getRequestBody(data) {
    return { recommendation: data };
  },
  invalidateQueries: [useReferralRecommendations.queryKey()],
  beforeMutate() {
    analytics.track("Updated Referral Recommendation");
  },
}));

export const useReferralRecommendation = generateApiQueryHook<
  ReferralRecommendation,
  string
>((id) => `/referral_recommendations/${id}`);

// Invites
export const useCreateInvite = generateApiMutationHook<
  { id: string },
  unknown,
  void
>(() => ({
  path: "/invites",
  method: "POST",
  beforeMutate() {
    analytics.track("Created Invite");
  },
  invalidateQueries: [useInvites.queryKey()],
}));

// Activities
export const useActivities = generateApiQueryHook<Activity[]>(
  () => "/activities"
);

export const useAllActivities = generateApiQueryHook<Activity[]>(
  () => "/activities/all_activities"
);

export const useGroupActivities = generateApiQueryHook<Activity[], string>(
  (id: string) =>
    id === "personal" || id === "all"
      ? "/activities"
      : `/groups/${id}/activities`
);

// Shortened URLs
export const useShortenedUrl = generateApiQueryHook<string, string>(
  (url: string) => `/shortened_urls/${encodeURIComponent(url)}`
);

export const useInvites = generateApiQueryHook<Invite[]>(() => "/invites");
