import { Box } from "native-base";
import React, { forwardRef, ReactNode } from "react";
import { Keyboard, Platform, TouchableWithoutFeedback } from "react-native";
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from "react-native-keyboard-aware-scroll-view";

/**
 * This component:
 *
 * - Provides a 100% height scroll view
 * - Is keyboard aware, meaning that it will scroll to provide room for the
 *   on-screen keyboard
 * - Allows buttons inside of it to be tapped even when the keyboard is up
 *   (keyboardShouldPersistTaps is handled)
 * - Dismisses the keyboard when tapping outside of the input area
 */
export default forwardRef(
  (
    {
      children,
      refreshControl,
      ...props
    }: {
      children?: ReactNode;
      refreshControl?: any;
    } & KeyboardAwareScrollViewProps,
    ref
  ) => (
    <KeyboardAwareScrollView
      innerRef={ref as any}
      refreshControl={refreshControl}
      style={{
        flexGrow: 1,
        // These props are required not to show an additional scrollbar on web
        flexBasis: Platform.OS === "web" ? "auto" : undefined,
        flexShrink: Platform.OS === "web" ? 0 : undefined,
      }}
      contentContainerStyle={{
        flexGrow: 1,
      }}
      keyboardShouldPersistTaps="handled"
      {...props}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        {/* Due to some weird phenomena where the touchble is taking a ref with respect to its child, 
          the children elements should be wrapped in a fragment. */}
        <>{children}</>
      </TouchableWithoutFeedback>
    </KeyboardAwareScrollView>
  )
);
