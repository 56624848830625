import { Box, Toast, Text } from "native-base";
import React from "react";
import { Dimensions } from "react-native";

import { theme } from "../brand/theme";
import { log } from "./logging";

export function makeToast(message: string) {
  log("making toast", message);
  const width = Dimensions.get("window").width;
  Toast.show({
    placement: width > theme.breakpoints.md ? "top-right" : "top",
    render() {
      return (
        <Box
          backgroundColor="white"
          p="3"
          borderRadius="lg"
          mr={{ base: "5", md: "10" }}
          ml={{ base: "5", md: "0" }}
          shadow={5}
          style={{
            shadowOpacity: 0.1,
          }}
        >
          <Text bold>{message}</Text>
        </Box>
      );
    },
  });
}
