import { LogBox } from "react-native";

// We ignore contrast ratio warnings for now. These *should* be fixed, but given
// our current state they're more of an annoyance.
LogBox.ignoreLogs(["NativeBase: The contrast ratio of"]);

// Ignore spurious key warning from Select dropdowns.
// See https://github.com/GeekyAnts/NativeBase/issues/4429.
LogBox.ignoreLogs(["Check the render method of `ForwardRef(createdIcon)`."]);

// HACK: Remove this immediately.
LogBox.ignoreLogs(["Promise Rejection"]);
