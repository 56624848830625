import {
  CommonActions,
  getStateFromPath,
  useNavigation,
} from "@react-navigation/native";
import * as Linking from "expo-linking";
import { useQueryClient } from "react-query";
//@ts-ignore
window.Linking = Linking;
import { useProfile } from "../api/hooks";
import { linking } from "../screens";
import { useInitialScreenStorage } from "./initial-screen";

export function useNextScreen() {
  const { refetch } = useProfile();
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  const initialScreen = useInitialScreenStorage();
  return async () => {
    const { data: user } = await refetch();
    queryClient.invalidateQueries(useProfile.queryKey());
    const screen = await initialScreen.getItem();

    if (!user) {
      navigation.navigate("Start");
    } else if (!user.name || !user.contact) {
      navigation.navigate("CompleteProfile");
    } else if (!user.completedOnboarding) {
      navigation.navigate("CreateReferralRequest");
    } else if (screen !== null && screen !== "/") {
      await initialScreen.removeItem();
      navigation.dispatch(
        CommonActions.reset(getStateFromPath(screen, linking.config))
      );
    } else {
      navigation.navigate("Main");
    }
  };
}
