import AsyncStorage from "@react-native-async-storage/async-storage";
import { useMutation, useQueryClient } from "react-query";

import { generateQueryHook } from "./hooks";

export type Flags = {
  useProdApiInDev: boolean;
};

export async function getFlags(): Promise<Flags> {
  const flags = await AsyncStorage.getItem("flags");
  return flags
    ? JSON.parse(flags)
    : {
        useProdApiInDev: false,
      };
}

export async function setFlags(flags: Flags) {
  await AsyncStorage.setItem("flags", JSON.stringify(flags));
}

export const useFlags = generateQueryHook(getFlags, () => ["flags"]);

export function useSetFlags() {
  const queryClient = useQueryClient();
  return useMutation(setFlags, {
    onSuccess: () => queryClient.invalidateQueries(["flags"]),
  });
}
