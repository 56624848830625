// On web, we initialize the web version of LogRocket.

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { isTestMode } from "../api/isTestMode";
import { log } from "../utils/logging";
import config from "./config";

if (!isTestMode()) {
  log("Initializing web version of logrocket");
  LogRocket.init(config.logrocket);
  setupLogRocketReact(LogRocket);
}
export { LogRocket };
