import { useIsFocused } from "@react-navigation/native";
import { StatusBar, StatusBarProps } from "expo-status-bar";
import * as React from "react";

/**
 * A status bar that only applies its changes when the screen its in is focused.
 * Required due to issues described on
 * https://reactnavigation.org/docs/status-bar/.
 */
export default function FocusAwareStatusBar(props: StatusBarProps) {
  const isFocused = useIsFocused();

  return isFocused ? <StatusBar {...props} /> : null;
}
