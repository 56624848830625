import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { FirebaseError } from "firebase/app";
import {
  EmailAuthProvider,
  getAuth,
  linkWithCredential,
  sendSignInLinkToEmail,
  signInWithCredential,
} from "firebase/auth";
import { useEffect } from "react";
import { AppState, Platform } from "react-native";
import { useMutation, useQueryClient } from "react-query";

import { queryKey } from "../api/hooks";
import { useNextScreen } from "../hooks/useNextScreen";
import { analytics } from "../init/mixpanel";
import { captureException } from "../utils/error-handling";
import { log } from "../utils/logging";
import { makeToast } from "../utils/toasts";

const waitForActive = (timeout: number = 5000) =>
  new Promise<void>((r, j) => {
    var check = () => {
      if (AppState.currentState === "active") r();
      else if ((timeout -= 100) < 0) j("timed out!");
      else setTimeout(check, 100);
    };
    setTimeout(check, 100);
  });

export function useEmailLoginLinkHandler() {
  const queryClient = useQueryClient();
  const nextScreen = useNextScreen();
  const navigation = useNavigation();

  async function handleLink(url: string) {
    const { path } = Linking.parse(url);
    if (!path) return;
    // Wait for app to enter active state before we try signing in, because it
    // seems iOS blocks background web requests
    await waitForActive();
    console.log("Got initial URL:", path, url);
    try {
      const segments = path.split("/");
      const route = segments[segments.length - 2];
      const possibleMail = segments[segments.length - 1];
      if (route === "finish-email-login") {
        const email = decodeURIComponent(possibleMail);
        const cred = EmailAuthProvider.credentialWithLink(email, url);

        await signInWithCredential(getAuth(), cred);
        makeToast("Successfully signed in!");
        analytics.track("Login: Signed in with Email");
        await nextScreen();
      } else if (route === "link-email") {
        const email = decodeURIComponent(possibleMail);
        log("email", email);
        const cred = EmailAuthProvider.credentialWithLink(email, url);

        await linkWithCredential(getAuth().currentUser!, cred);
        queryClient.invalidateQueries(queryKey("/user"));
        makeToast("Successfully linked email");

        analytics.track("Login: Linked Email");
        await nextScreen();
      }
    } catch (e) {
      // Silently allow invalid action code error.
      if (e instanceof FirebaseError && e.code === "auth/invalid-action-code") {
        return;
      }
      makeToast("Sorry, something went wrong validating the email.");
      console.error(e);
      captureException(e);
    }
  }
  useEffect(() => {
    function foregroundHandler(event: Linking.EventType) {
      handleLink(event.url);
    }
    Linking.addEventListener("url", foregroundHandler);
    return () => Linking.removeEventListener("url", foregroundHandler);
  }, []);
  useEffect(() => {
    (async () => {
      const url = await Linking.getInitialURL();
      if (url) {
        await handleLink(url);
      }
    })();
  }, []);
}

function getLoginUrl(email: string, action: string) {
  const prefix = action === "link" ? "link-email/" : "finish-email-login/";
  if (Platform.OS === "ios" || Platform.OS === "android") {
    const expoLink = Linking.createURL("chord/" + prefix + email);
    return `https://chordapp.io/api/redirect?redirectUrl=${encodeURIComponent(
      expoLink
    )}`;
  } else if (Platform.OS === "web") {
    // Web link
    return `${location.origin}/chord/${prefix}${encodeURIComponent(email)}`;
  } else {
    throw new Error("Unsupported platform for email linking");
  }
}

export const useSendEmailLink = (action: "link" | "login") => {
  return useMutation(async ({ email }: { email: string }) => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: getLoginUrl(email, action),
      // This must be true.
      handleCodeInApp: true,
    };

    log("Sending email link with settings", actionCodeSettings);

    await sendSignInLinkToEmail(getAuth(), email, actionCodeSettings);
  });
};
