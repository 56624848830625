import { isTestMode } from "../api/isTestMode";

const devConfig = {
  webUrl: "http://localhost:19006",
  logrocket: "ezyce6/chord-development",
  mixpanel: "37e886d58d817b8e98abee3400673a48",
  sentryEnv: "development",
};

const prodConfig = {
  webUrl: "https://web.chordapp.io",
  logrocket: "ezyce6/chord",
  mixpanel: "3aba1ce2b0e8637339d15d938c36ba48",
  sentryEnv: "production",
};

export default __DEV__ || isTestMode() ? devConfig : prodConfig;
