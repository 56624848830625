import * as Font from "expo-font";

import { generateQueryHook } from "../api/hooks";
import { log } from "../utils/logging";

export const useLoadFonts = generateQueryHook(
  async () => {
    await Font.loadAsync({
      // "AvantGarde-Demi": require("../assets/fonts/AvantGarde-Demi.ttf"),
      "AvantGarde-Bold": require("../assets/fonts/AvantGarde-Bold.otf"),
      // Bold
      "Inter-Bold": require("../assets/fonts/Inter/Inter-Bold.otf"),
      "Inter-BoldItalic": require("../assets/fonts/Inter/Inter-BoldItalic.otf"),
      // Regular
      "Inter-Italic": require("../assets/fonts/Inter/Inter-Italic.otf"),
      "Inter-Regular": require("../assets/fonts/Inter/Inter-Regular.otf"),
      // Semibold
      "Inter-SemiBold": require("../assets/fonts/Inter/Inter-SemiBold.otf"),
      "Inter-SemiBoldItalic": require("../assets/fonts/Inter/Inter-SemiBoldItalic.otf"),
    });
    log("Font loading completed");
    return null;
  },
  () => ["fonts"]
);
