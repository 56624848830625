import { useNavigationState } from "@react-navigation/native";
import React, {
  createContext,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { InteractionManager } from "react-native";
import Explosion from "react-native-confetti-cannon";

export const ConfettiContext = createContext<(() => void) | null>(null);

export default function ConfettiProvider({
  children,
}: {
  children: ReactNode;
}) {
  const ref = useRef<Explosion>(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const navigation = useNavigationState((state) => state);

  function shootConfetti() {
    setShowConfetti(true);
    InteractionManager.runAfterInteractions(() => ref.current?.start());
  }

  // Disable confetti when navigation state changes
  useEffect(() => {
    setShowConfetti(false);
  }, [navigation]);

  return (
    <ConfettiContext.Provider value={shootConfetti}>
      {children}

      {showConfetti && (
        <Explosion
          count={200}
          fadeOut
          origin={{ x: -10, y: -10 }}
          autoStart={false}
          ref={ref}
        />
      )}
    </ConfettiContext.Provider>
  );
}
