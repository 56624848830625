// Initialization
import "./init/logrocket";
import "./init/firebase";
import "./init/sentry";
import "./init/nativebase";

import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import * as SplashScreen from "expo-splash-screen";
import { NativeBaseProvider } from "native-base";
import React, { useEffect, useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { useLoadFonts } from "./init/fonts";

import { enableFreeze } from "react-native-screens";
import { useWaitForFirebase } from "./auth/useWaitForFirebase";
import { theme } from "./brand/theme";
import BetterSuspense from "./components/BetterSuspense";
import { persistOptions, queryClient } from "./init/queryClient";
import Navigation from "./screens";
import { log } from "./utils/logging";
import { View } from "react-native";
import MetaDescription from "./components/MetaDescription";
import BetterScrollView from "./components/BetterScrollView";
import { PersistQueryClientProvider } from "react-query/persistQueryClient";

log("Initializing Chord on CPU 0x00000000");
log("Reticulating splines...");

enableFreeze(false);

function App() {
  const { isReady: firebaseReady } = useWaitForFirebase();
  const [completedLayout, setCompletedLayout] = useState(false);

  const { isSuccess: fontsLoaded } = useLoadFonts(undefined, {
    // Never refetch this query, but also don't keep it in memory.
    cacheTime: 0,
    staleTime: 0,
  });

  const isReady = firebaseReady && fontsLoaded;

  useEffect(() => {
    // Keep the loading screen ready until everything is ready
    SplashScreen.preventAutoHideAsync();
  }, []);

  // When we've completed initial layout, hide the loading screen
  useEffect(() => {
    if (completedLayout) {
      SplashScreen.hideAsync();
    }
  }, [completedLayout]);

  if (!isReady) {
    return null;
  } else {
    return (
      <View style={{ flex: 1 }} onLayout={() => setCompletedLayout(true)}>
        <Navigation />
      </View>
    );
  }
}

export default () => (
  <PersistQueryClientProvider
    persistOptions={persistOptions}
    client={queryClient}
  >
    <NativeBaseProvider theme={theme}>
      <SafeAreaProvider>
        <ActionSheetProvider>
          {
            // This scroll view is our root scrollview, used on web as an alternative to body scrolling. By putting NBProvider and ActionSheetProvider outside of this scrollview, we can effectively hold things fixed on the page while scrolling around.
          }
          <BetterScrollView showsVerticalScrollIndicator bounces={false}>
            <BetterSuspense useSafeArea>
              <MetaDescription
                title="Chord"
                description="Chord facilitates intentional, contextualized introductions through trusted mutuals."
              />
              <App />
            </BetterSuspense>
          </BetterScrollView>
        </ActionSheetProvider>
      </SafeAreaProvider>
    </NativeBaseProvider>
  </PersistQueryClientProvider>
);
