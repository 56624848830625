import { Platform } from "react-native";
import Helmet from "react-helmet";
import ellipsize from "ellipsize";
function getPreviewUrl() {
  return `https://mugshotbot.com/m?color=ff6b00&hide_watermark=true&mode=light&pattern=bank_note&url=${encodeURIComponent(
    location.href
  )}`;
}

export default function MetaDescription({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  description = ellipsize(description.replace("\n", " "), 150);
  if (Platform.OS === "web") {
    return (
      // Mostly copied from web/index.html.
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@getchordapp" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={getPreviewUrl()} />

        <meta property="og:title" content={title} />
        <meta property="og:site_name" content="Chord" />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={getPreviewUrl()} />
      </Helmet>
    );
  } else {
    return null;
  }
}
