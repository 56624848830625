// On web, we code-split the bundle by route to reduce bundle size. This isn't
// necessary on mobile, because Hermes can mmap the js bundle file so it doesn't
// actually suffer any loading delays.
import React from "react";

export default {
  // Put LPN, Start, and LE in the same bundled file to avoid flash of white
  // when moving from Start to LPN/LE.
  LoginPhoneNumber: React.lazy(() =>
    import("./login").then((l) => ({ default: l.default.LoginPhoneNumber }))
  ),
  Start: React.lazy(() =>
    import("./login").then((l) => ({ default: l.default.Start }))
  ),
  LoginEmail: React.lazy(() =>
    import("./login").then((l) => ({ default: l.default.LoginEmail }))
  ),
  Onboarding: React.lazy(() => import("./Onboarding")),
  MainTabNavigator: React.lazy(() => import("./main/MainTabNavigator")),
  CreateReferral: React.lazy(() => import("./CreateReferral")),
  EULA: React.lazy(() => import("./EULA")),
  About: React.lazy(() => import("./About")),
  Groups: React.lazy(() => import("./Groups")),
  Group: React.lazy(() => import("./Group")),
  AddGroup: React.lazy(() => import("./AddGroup")),
  Invite: React.lazy(() => import("./Invite")),
  CreateInvite: React.lazy(() => import("./create/CreateInvite")),
  PublicProfile: React.lazy(() => import("./public-profile")),
  CompleteProfile: React.lazy(() => import("./CompleteProfile")),
  Connections: React.lazy(() => import("./Connections")),
  Notifications: React.lazy(() => import("./main/Notifications")),
  CreateReferralRequest: React.lazy(
    () => import("./create/CreateReferralRequest")
  ),
  CreateBlurb: React.lazy(() => import("./create/CreateBlurb")),
  ChatRoom: React.lazy(() => import("./ChatRoom")),
  EditProfile: React.lazy(() => import("./main/EditProfile")),
  AddContact: React.lazy(() => import("./AddContact")),
  MobileWarning: React.lazy(() => import("./MobileWarning")),
  NavBar: React.lazy(() => import("../components/layout/NavBar")),
};
