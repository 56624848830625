import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import { AppState, AppStateStatus } from "react-native";
import { QueryClient, focusManager } from "react-query";
import { createAsyncStoragePersister } from "react-query/createAsyncStoragePersister";
import { persistQueryClientSave } from "react-query/types/persistQueryClient";
import { log } from "../utils/logging";

const CACHE_TIME = 1 * 24 * 60 * 60 * 1000; // 1 day

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: true,
      cacheTime: CACHE_TIME,
      staleTime: 1, // treat all cached queries as stale
    },
  },
});

const STORAGE_KEY = "REACT_QUERY_PERSIST_STATE";

// Persist query state to Async Local Storage.
const persister = createAsyncStoragePersister({
  storage: AsyncStorage,
  key: STORAGE_KEY,
  throttleTime: 1000,
  serialize: (state) => {
    const now = Date.now();
    const serialized = JSON.stringify(state);
    log(
      "Serialization took",
      Date.now() - now,
      "ms to serialize",
      serialized.length,
      "chars"
    );
    return serialized;
  },
});

export const persistOptions = {
  persister,
  // Use a unique storage key per release version, because code changes might
  // affect the type of what each query key returns.
  buster: Constants.manifest!.version!,
  maxAge: CACHE_TIME,
};

focusManager.setEventListener((handleFocus) => {
  function listener(state: AppStateStatus) {
    handleFocus(state === "active");
  }

  AppState.addEventListener("change", listener);

  return () => {
    AppState.removeEventListener("change", listener);
  };
});
