import { captureMessage } from "./error-handling";

export const log = (message: string, ...optionalParams: any[]) => {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams);
  } else {
    // Logging to Sentry or another bug-tracking system
    captureMessage(message);
  }
};
