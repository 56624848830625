import { getAuth, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { captureException } from "../utils/error-handling";
import { log } from "../utils/logging";

export function useWaitForFirebase() {
  const [isReady, setReady] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    async function prepare() {
      log("Registering for auth state changes from Firebase");
      try {
        const unsubscribe = getAuth().onAuthStateChanged((user) => {
          // Allow login
          log("Firebase initialized!");
          unsubscribe();
          setUser(user);
          setReady(true);
        });
      } catch (e) {
        captureException(e);
        // Tell the application to render anyway, I guess.
        setReady(true);
      }
    }

    prepare();
  }, []);

  return { user, isReady };
}
