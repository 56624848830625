// We handle notification responses here - e.g. if the user taps a
// notification that has a specific page attached to it, we can navigate to

import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";

import N from "../utils/notifications";

type NotifContent = {
  notifiable_type: string;
  notifiable_id: string;
  action: string;
};

export default function useHandleNotifAction() {
  const lastNotifResponse = N.useLastNotificationResponse();
  const navigation = useNavigation();
  useEffect(() => {
    if (
      lastNotifResponse &&
      lastNotifResponse.actionIdentifier === N.DEFAULT_ACTION_IDENTIFIER &&
      lastNotifResponse.notification.request.content.data.action !== undefined
    ) {
      const content = lastNotifResponse.notification.request.content
        .data as NotifContent;
      if (content.notifiable_type === "Room") {
        // Open the room screen
        navigation.navigate("ChatRoom", {
          id: content.notifiable_id,
        });
      }
    }
  }, [lastNotifResponse]);
}
