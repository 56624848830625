/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { LogRocket } from "../init/logrocket";
import { Suspense, useEffect, useRef, useState } from "react";
import useHandleNotifAction from "../hooks/useHandleNotifAction";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import FocusAwareStatusBar from "../components/FocusAwareStatusBar";
import { brandColors } from "../brand/colors";
import * as Analytics from "expo-firebase-analytics";
import {
  useApiHost,
  useProfile,
  useSiteHost,
  useUserStatus,
} from "../api/hooks";
import { useEmailLoginLinkHandler } from "../auth/hooks";
import { analytics } from "../init/mixpanel";

import { Platform } from "react-native";

import * as React from "react";

import { AlertModal } from "../components/AlertModal";
import { useUpdateChecker } from "../hooks/updates";
import { RootStackParamList } from "../types";
import { log } from "../utils/logging";
import { setStatusBarStyle } from "expo-status-bar";
import * as Linking from "expo-linking";
import ExpoMixpanelAnalytics from "@bothrs/expo-mixpanel-analytics";
import ConfettiProvider from "../components/ConfettiProvider";
import { Host } from "react-native-portalize";
import { ShowNavbarContext } from "../components/layout/NavBar/Context";
import BetterSuspense from "../components/BetterSuspense";

// Hard bundled routes
// Lazy-loaded bundles
import routes from "./routes";

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [
    Linking.createURL("/"),
    "https://web.chordapp.io",
    "https://chordapp.io",
  ],
  config: {
    screens: {
      Start: "/",
      LoginPhoneNumber: "/login/phone",
      LoginEmail: "/login/email",
      CompleteProfile: "/onboarding/complete-profile",
      Onboarding: "/onboarding/import",
      EULA: "/onboarding/eula",
      Main: {
        screens: {
          Feed: "/feed",
          Explore: "/explore",
          ForYou: "/intros",
          Profile: "/profile",
        },
      } as any,
      EditProfile: "/profile/edit",
      ChatRoom: "/chat/:id",
      CreateBlurb: "/blurbs/new",
      CreateReferral: "/intros/new",
      CreateReferralFromUsername: "/intros/:referentId",
      CreateReferralRequest: "/goals/new",
      CreateReferralFromGoal: "/goals/:goalId",
      CreateInvite: "/invites/new",
      Invite: "/invites/:userId",
      Groups: "/groups",
      AddGroup: "/groups/new",
      Group: "/groups/:id",
      AddContact: "/connections/new",
      Notifications: "/notifications",
      About: "/about",
      MobileWarning: "/mobile",
      PublicProfile: {
        path: "/:id",
      },
      Connections: "/:id/connections",
    },
  },
};

export default function Navigation() {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef<string>();

  const status = useUserStatus();
  const [navbarVisible, setNavbarVisible] = useState(true);

  return (
    <NavigationContainer
      ref={navigationRef}
      // Web configuration
      linking={linking}
      documentTitle={{
        formatter: (_options, _route) => `Chord`,
      }}
      // Analytics
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
      }}
      onStateChange={async () => {
        const route = navigationRef.getCurrentRoute();
        const previousRouteName = routeNameRef.current;
        const currentRouteName = route?.name;

        log("Navigated to", route);

        // Manage status bar color
        if (route?.name === "Profile" || route?.name === "PublicProfile") {
          setStatusBarStyle("light");
        } else {
          setStatusBarStyle("dark");
        }

        // Manage analytics
        if (previousRouteName !== currentRouteName) {
          // The line below uses the expo-firebase-analytics tracker
          // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
          // Change this line to use another Mobile analytics SDK
          await Analytics.logEvent("screen_view", {
            screen_name: currentRouteName,
          });
          analytics.track("Screen View", {
            screen_name: currentRouteName,
          });
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: "white",
        },
      }}
    >
      <Host>
        <ShowNavbarContext.Provider
          value={{
            visible: navbarVisible,
            setVisible: setNavbarVisible,
          }}
        >
          <FocusAwareStatusBar
            // We only support light theme for now.
            style="dark"
          />

          {status === "logged-in" && Platform.OS === "web" && <routes.NavBar />}
          <AlertModal />
          <ConfettiProvider>
            <BetterSuspense>
              <RootNavigator />
            </BetterSuspense>
          </ConfettiProvider>

          <Suspense fallback={<></>}>{/* <DevBar /> */}</Suspense>

          <Suspense fallback={<></>}>
            <AnalyticsUpdater />
          </Suspense>
        </ShowNavbarContext.Provider>
      </Host>
    </NavigationContainer>
  );
}

function AnalyticsUpdater() {
  const { data: profile } = useProfile();

  // Set analytics profile id
  useEffect(() => {
    if (profile) {
      Analytics.setUserId(profile.id);
      analytics.identify(profile.id);
      if (Platform.OS === "web") {
        analytics.people.set_once({
          $name: profile.name,
          $email: profile.email ?? "",
          flagRecommendationsBeta: profile.flagRecommendationsBeta,
        });
      } else {
        (analytics as unknown as ExpoMixpanelAnalytics).people_set_once({
          $name: profile.name,
          $email: profile.email ?? "",
          flagRecommendationsBeta: profile.flagRecommendationsBeta,
        });
      }
      LogRocket.identify(profile.id, {
        name: profile.name,
        email: profile.email ?? "",
        phoneNumber: profile.phoneNumber ?? "",
        flagRecommendationsBeta: profile.flagRecommendationsBeta,
      });
    }

    return () => {
      Analytics.setUserId("");
      analytics.identify("");
      LogRocket.identify("");
    };
  }, [profile]);

  return null;
}

function useInitialScreen(): keyof RootStackParamList {
  // Suspending call.
  const status = useUserStatus();
  log("User status for initial routing:", status);

  return {
    "logged-in": "Main",
    "not-logged-in": "Start",
    onboarding: "CompleteProfile",
  }[status] as keyof RootStackParamList;
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  useSiteHost.usePrefetch();
  useApiHost.usePrefetch();
  useUpdateChecker();
  useHandleNotifAction();
  useEmailLoginLinkHandler();

  const showHeaders = Platform.OS === "ios" || Platform.OS === "android";

  return (
    <Stack.Navigator initialRouteName={useInitialScreen()}>
      <Stack.Group
        // Unnavigatable no-gesture-controlled screens
        screenOptions={{
          gestureEnabled: false,
          headerShown: false,
        }}
      >
        <Stack.Screen name="Start" component={routes.Start} />
        <Stack.Screen
          name="LoginPhoneNumber"
          component={routes.LoginPhoneNumber}
        />
        <Stack.Screen name="LoginEmail" component={routes.LoginEmail} />
        <Stack.Screen
          name="CompleteProfile"
          component={routes.CompleteProfile}
        />
        <Stack.Screen name="Onboarding" component={routes.Onboarding} />
        <Stack.Screen name="EULA" component={routes.EULA} />
        <Stack.Screen name="Main" component={routes.MainTabNavigator} />
      </Stack.Group>
      <Stack.Group
        // Gesture-controllable subscreens
        screenOptions={{
          gestureEnabled: true,
          headerShown: false,
        }}
      >
        <Stack.Screen name="About" component={routes.About} />
        <Stack.Screen name="Groups" component={routes.Groups} />
        <Stack.Screen name="Group" component={routes.Group} />
        <Stack.Screen name="Invite" component={routes.Invite} />
        <Stack.Screen name="PublicProfile" component={routes.PublicProfile} />
        <Stack.Screen name="Connections" component={routes.Connections} />
        <Stack.Screen name="Notifications" component={routes.Notifications} />
        <Stack.Screen
          name="CreateReferralRequest"
          component={routes.CreateReferralRequest}
        />
        <Stack.Screen name="CreateReferral" component={routes.CreateReferral} />
        <Stack.Screen
          name="CreateReferralFromUsername"
          component={routes.CreateReferral}
        />
        <Stack.Screen
          name="CreateReferralFromGoal"
          component={routes.CreateReferral}
        />
        <Stack.Screen name="CreateBlurb" component={routes.CreateBlurb} />
        <Stack.Screen name="CreateInvite" component={routes.CreateInvite} />
        <Stack.Screen name="MobileWarning" component={routes.MobileWarning} />
      </Stack.Group>
      <Stack.Group
        // Sub-screens with gesture and header
        screenOptions={{
          gestureEnabled: true,
          headerShown: showHeaders,
        }}
      >
        <Stack.Screen
          name="EditProfile"
          component={routes.EditProfile}
          options={{
            title: "Edit Profile",
            headerTitleStyle: {
              color: "black",
            },
            headerTintColor: brandColors.brand.main,
            headerStyle: {
              backgroundColor: "#fff",
            },
          }}
        />
        <Stack.Screen
          name="ChatRoom"
          component={routes.ChatRoom}
          options={{
            title: "", // Filled in when room loads
            headerTintColor: brandColors.brand.main,
            headerTitleStyle: {
              color: "black",
            },
          }}
        />
      </Stack.Group>
      <Stack.Group
        // Modals
        screenOptions={{ presentation: "modal", headerShown: false }}
      >
        <Stack.Screen name="AddContact" component={routes.AddContact} />
        <Stack.Screen name="AddGroup" component={routes.AddGroup} />
      </Stack.Group>
    </Stack.Navigator>
  );
}
