import Constants from "expo-constants";
import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import { LogBox, Platform } from "react-native";

// The Firebase recaptcha verifier modal uses the compat implementation of
// Firebase, so we need to initialize it separately to avoid an error that it
// can't find a default app.

// Suppress unnecessary warning
// See https://github.com/firebase/firebase-js-sdk/issues/1847
LogBox.ignoreLogs([
  "AsyncStorage has been extracted from react-native core and will be removed in a future release",
]);
LogBox.ignoreAllLogs();

export const app = initializeApp(Constants.manifest!.web!.config!.firebase!);

if (Platform.OS === "web") {
  LogBox.ignoreAllLogs();
}
firebase.initializeApp(app.options);
