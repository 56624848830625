import { extendTheme } from "native-base";
import { Platform } from "react-native";

import { brandColors } from "./colors";

export const theme = extendTheme({
  colors: {
    brand: {
      100: brandColors.brand.main,
      300: "#ee6a3f",
      500: "#f76948",
    },
    primary: {
      "50": "#fafafa",
      "100": "#f4f4f5",
      "200": "#e4e4e7",
      "300": "#d4d4d8",
      "400": "#a1a1aa",
      "500": "#71717a",
      "600": "#52525b",
      "700": "#3f3f46",
      "800": "#27272a",
      "900": "#18181b",
    },
    background: "#ffffff",
  },
  components: {
    // We hardcode heading themes here, but replace the null in the middle of
    // the responsive array with the copied value from the previous breakpoint.
    // This solves a bug on web where headers become very small at certain
    // breakpoints that doesn't appear to show up on mobile.
    // See https://github.com/GeekyAnts/NativeBase/tree/master/src/theme/components/heading.ts
    Heading: {
      sizes: {
        "4xl": {
          fontSize: ["6xl", "6xl", "7xl"],
          letterSpacing: "xl",
        },
        "3xl": {
          fontSize: ["5xl", "5xl", "6xl"],
          letterSpacing: "xl",
        },
        "2xl": {
          fontSize: ["4xl", "4xl", "5xl"],
        },
        xl: {
          fontSize: ["3xl", "3xl", "4xl"],
        },
        lg: {
          fontSize: ["2xl", "2xl", "3xl"],
        },
        md: { fontSize: "xl" },
        sm: { fontSize: "md" },
        xs: { fontSize: "sm" },
      },
    },
    Input: {
      defaultProps: {
        // Removes the default blue highlighting
        borderWidth: "1px",
        _focus: {
          bg: brandColors.brand.lightGrey,
          _stack: {
            style: {
              outlineWidth: "0px",
            },
          },
        },
      },
    },
    Toast: {
      baseStyle: {
        _stack: {
          // Fixed position on the web so that it stays in the viewport.
          position: Platform.OS === "web" ? "fixed" : "absolute",
          zIndex: 100,
        },
      },
    },
    Modal: {
      defaultProps: {
        _fade: { entryDuration: 100, exitDuration: 50 },
        _backdropFade: { entryDuration: 100, exitDuration: 50 },
      },
    },
  },
  fontConfig: {
    Inter: {
      400: {
        normal: "Inter-Regular",
        italic: "Inter-Italic",
      },
      600: {
        normal: "Inter-SemiBold",
        italic: "Inter-SemiBoldItalic",
      },
      700: {
        normal: "Inter-Bold",
        italic: "Inter-BoldItalic",
      },
    },
    AvantGarde: {
      700: {
        normal: "AvantGarde-Bold",
      },
    },
  },
  fonts: {
    body: "Inter",
    heading: "AvantGarde",
  },
});
