import * as Sentry from "sentry-expo";
import config from "./config";

Sentry.init({
  dsn: "https://820b2a850e6f46e7bb168aea2e0a2e85@o1101254.ingest.sentry.io/6127034",
  // We ignore this error because it's spurious and shows up when the user
  // switches off the Feed page.
  ignoreErrors: ["RecyclerListView needs to have a bounded size."],
  environment: config.sentryEnv,
  enableInExpoDevelopment: true,
});
