import * as Updates from "expo-updates";
import { useEffect, useRef, useState } from "react";
import { Alert, AppState, AppStateStatus, Platform } from "react-native";

import { captureException } from "../utils/error-handling";
import { log } from "../utils/logging";

// This is sort of a hack
let alertShown = false;

function useAppState() {
  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  const _handleAppStateChange = (nextAppState: AppStateStatus) => {
    appState.current = nextAppState;
    setAppStateVisible(appState.current);
  };

  useEffect(() => {
    AppState.addEventListener("change", _handleAppStateChange);

    return () => {
      AppState.removeEventListener("change", _handleAppStateChange);
    };
  }, []);

  return appStateVisible;
}

async function checkUpdate() {
  if (__DEV__) {
    return;
  }
  if (Platform.OS === "web") {
    log("Skipping update check in web");
    return;
  }

  try {
    const update = await Updates.checkForUpdateAsync();
    if (update.isAvailable) {
      await Updates.fetchUpdateAsync();
      if (!alertShown) {
        // Ok to use Alert here because we only show this on mobile
        Alert.alert(
          "Update Available",
          "An update is available. Click Restart to update.",
          [
            {
              text: "Restart",
              style: "destructive",
              onPress: () => Updates.reloadAsync(),
            },
            {
              text: "Cancel",
              style: "cancel",
              onPress: () => {},
            },
          ]
        );
        alertShown = true;
      }
    }
  } catch (e) {
    console.error(e);
    // Let exception pass, since we don't want to crash the app
    captureException(e);
  }
}

export function useUpdateChecker() {
  const appState = useAppState();

  // Check for updates whenever app is foregrounded, and every 10 min
  useEffect(() => {
    if (!alertShown) {
      const timer = setInterval(checkUpdate, 5 * 60 * 1000);
      return () => clearInterval(timer);
    }
  }, []);

  useEffect(() => {
    checkUpdate();
  }, [appState === "active"]);
}
