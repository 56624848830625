import { Button, Modal, Pressable, Image } from "native-base";
import React, { ReactNode } from "react";
import { Alert, Platform } from "react-native";

export type AlertData = {
  title: string;
  message: string;
  richMessage?: ReactNode;
  isGoogleSignIn?: boolean;
};

export function useNativeAlerts() {
  return Platform.OS !== "web";
}

/**
 * Cross-platform modal component. On web, it shows a NativeBase modal. On
 * mobile, it shows a native modal in the style of the OS.
 *
 * Use like:
 *
 * AlertModal.confirm(...)
 * AlertModal.prompt(...)
 */
export class AlertModal extends React.Component<
  { useNativeAlerts?: boolean },
  { visible: boolean; type: "prompt" | "confirm" } & AlertData
> {
  constructor(props: { useNativeAlerts?: boolean }) {
    super(props);
    this.state = { visible: false, type: "prompt", message: "", title: "" };
  }

  static defaultProps = {
    useNativeAlerts: Platform.OS !== "web",
  };

  static confirm(data: AlertData) {
    // On mobile platforms, use native confirmation dialog
    const instance = AlertModal.alertInstance;
    if (instance?.props.useNativeAlerts) {
      return new Promise<boolean>((res) =>
        Alert.alert(data.title, data.message, [
          {
            text: "OK",
            onPress: () => res(true),
          },
          {
            text: "Cancel",
            onPress: () => res(false),
            style: "cancel",
          },
        ])
      );
    }

    instance?.setState({ visible: true, type: "confirm", ...data });
    return new Promise<boolean>((res) => {
      instance!.onConfirm = () => res(true);
      instance!.onCancel = () => res(false);
    });
  }

  static prompt(data: AlertData) {
    // On mobile platforms, use native prompt dialog
    const instance = AlertModal.alertInstance;
    if (instance?.props.useNativeAlerts) {
      return new Promise<void>((res) =>
        Alert.alert(data.title, data.message, [
          {
            text: "OK",
            onPress: () => res(),
          },
        ])
      );
    }

    instance?.setState({ visible: true, type: "prompt", ...data });
    return new Promise<void>((res) => {
      instance!.onConfirm = res;
      instance!.onCancel = res;
    });
  }

  static alertInstance: AlertModal | undefined = undefined;

  private onConfirm = () => {};
  private onCancel = () => {};

  componentDidMount() {
    AlertModal.alertInstance = this;
  }

  render() {
    if (this.props.useNativeAlerts) {
      return null;
    }

    return (
      <Modal
        zIndex={100}
        isOpen={this.state.visible}
        onClose={() => {
          this.onCancel();
          this.setState({ visible: false });
        }}
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton testID="alert-modal__cancel" />
          <Modal.Header>{this.state.title}</Modal.Header>
          <Modal.Body>
            {this.state.richMessage ?? this.state.message}
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              {this.state.type === "confirm" ? (
                <>
                  <Button
                    background="black"
                    onPress={() => {
                      this.onCancel();
                      this.setState({ visible: false });
                    }}
                  >
                    Cancel
                  </Button>
                  {this.state.isGoogleSignIn ? (
                    // Google sign in button
                    <Pressable
                      onPress={() => {
                        this.onConfirm();
                        this.setState({ visible: false });
                      }}
                    >
                      <Image
                        source={require("../assets/images/btn_google_signin_light_normal_web2x.png")}
                        width={191}
                        height={46}
                        resizeMode="contain"
                        alt="Sign in with Google"
                        testID="google-signin"
                      />
                    </Pressable>
                  ) : (
                    // Normal OK button
                    <Button
                      background="black"
                      onPress={() => {
                        this.onConfirm();
                        this.setState({ visible: false });
                      }}
                    >
                      OK
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  background="black"
                  onPress={() => {
                    this.onConfirm();
                    this.setState({ visible: false });
                  }}
                >
                  OK
                </Button>
              )}
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  }
}
